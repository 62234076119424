<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- 版心 -->
    <div id="content_box">
      <!-- 左边内容 -->
      <div class="content_left">
        <div class="news_title">{{ news.title }}</div>
        <div class="start_tinme">{{ news.createtime }}</div>
        <div class="news_content" v-html="news.content"></div>
      </div>
      <!-- 右边内容 -->
      <div class="content_right">
        <img :src="code_img" alt="勤学教育" />
        <div>
          微信扫一扫<br />
          下载勤学课堂APP
        </div>
      </div>
      <!-- 版心结尾 -->
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      news: [], //咨询内容
      code_img:
        "https://res-qd.qinxueedu.cn/uploads/prod/20230412/86956d8b637e37f3c89303956844ef2b.png", // 二维码
      isnews_id: "", //资讯id
    };
  },

  methods: {
    // 资讯详情接口
    getNewsInfo() {
      var datas = {
        article_id: this.isnews_id,
      };
      api.detail(datas).then((res) => {
        // if (res.data.data.metatype == 2) {
        //   window.open(res.data.data.content)
        //   history.go(-1)
        // } else {
        this.news = res.data.data;
        // }
      });
    },
    // APP下载二维码
    getImgCode() {
      // api.download_qrcode().then((res) => {
      //   this.code_img = res.data.data.qrcode;
      // });
    },
  },
  created() {
    // 当前页面id
    this.isnews_id = this.$route.params.info_id;
    this.getNewsInfo();
    this.getImgCode();
  },
};
</script>

<style lang="scss" scoped>
/deep/nav {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.06);
}

#content_box {
  width: 1100px;
  /* height: 1600px; */
  overflow: hidden;
  margin: 60px auto 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 60px;
}

/* 左边内容 */

.content_left {
  width: 850px;
}

.content_left .news_title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #424242;
}

.content_left .start_tinme {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #8f8f8f;
  margin: 18px auto 49px;
}

/* 右边内容 */

.content_right {
  width: 181px;
  height: 237px;
  border: 1px solid #dadada;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.content_right img {
  width: 114px;
  height: 114px;
}

.content_right div {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #424242;
  text-align: center;
  margin-top: 19px;
}
</style>
